<template>
  <div class="GlobalIssueStatus">
    <AppBarLayout>
      <template #header>
        {{ $t('issue.DefaultIssueStatuses') }}
      </template>
    </AppBarLayout>

    <div class="GlobalIssueStatus__scrollable">
      <IssueStatusManage
        ref="issueStatusManage"
        class="GlobalIssueStatus__manage"
        platform-name="frigate"
        @touch="dirty = true"
        @update:saving="saving = $event"
      />
    </div>

    <div class="GlobalIssueStatus__actions">
      <v-btn
        color="primary"
        depressed
        :disabled="!dirty"
        :loading="saving"
        @click="$refs.issueStatusManage.submit()"
      >
        {{ $t('issue.Save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { IssueStatusManage } from '@hexway/shared-front'

import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'GlobalIssueStatus',

  components: {
    AppBarLayout,
    IssueStatusManage,
  },

  data() {
    return {
      dirty: false,
      saving: false,
    }
  },

  mounted() {
    this.$store.dispatch('$issueStatus/getTemplates')
  },
}
</script>

<style lang="sass" scoped>
.GlobalIssueStatus
  position: relative
  background: var(--v-lightBackground-base, white)
  height: calc(100vh - 40px - 44px)

  display: flex
  flex-direction: column

  &__scrollable
    padding: 28px 12px

    height: calc(100vh - 40px - 44px - 76px)
    overflow: hidden auto

  &__manage
    margin-top: 24px
    max-width: 542px

  &__actions
    margin-top: auto
    height: 76px
    padding: 14px 32px 0
    border-top: 1px solid rgba(0, 0, 0, 0.12)
</style>
